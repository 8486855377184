@import "../../common-app-variables.scss";

/* styles for components in Flow Area*/
.empty-cell {
  width: 240px;
  height: 240px;
  border: 0 solid red;
}

.flow-area {
  margin-bottom: 150px;
  margin-left: 250px;
  text-align: center;
  width: 200%;
}

.zoom-buttons {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: $zIndexOfZoomButtons;
}

.ui.three.column.padded.grid .second{
  padding-top: 0;
  padding-bottom: 0;
}

.ui.three.column.padded.grid .first{
  padding-top: 0;
  padding-bottom: 0;
}

.double-simple.flowBox.active-cell{
  border: 1px solid darkseagreen !important;
  box-shadow: 5px 5px 15px 1px darkseagreen !important;
}

.double-simple.flowBox.active-cell-with-error{
  border: 1px solid #e02222 !important;
  box-shadow: 5px 5px 15px 1px #e02222 !important;
  background: linear-gradient(#f59c9c 30px, #f4f6f8 0%) !important;
  .header {
    color: white;
  }
}

.double-simple.flowBox.cell-with-error{
  border: 1px solid #e02222 !important;
  box-shadow: 5px 5px 15px 1px #e02222 !important;
  .header {
    color: white;
  }
}

.double-simple.flowBox.cell-with-error-back{
    background: linear-gradient(#f59c9c 30px, #f4f6f8 0%) !important;
    .header {
      color: white;
    }
}

.double-simple.flowBox {
    z-index: $zIndexOfBigSquareInMatrix;
    background: linear-gradient(#ffffff 30px, #f4f6f8 0%);
    border: 1px solid #919eab !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    box-shadow: 5px 5px 15px 1px #3a3b3c;

    .header {
      padding-top: 3%;
      margin: 0 -8% 0 -8%;
      width: 180px;
      height: 30px;
      border-radius: 8px 8px 0 0;
      color: #919eab;
      font-size: 120%;
      font-weight: 700;
      .cls-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }
    .choice-buttons {
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 0 1px rgba(0.2, 0.2, 0.2, 0.2);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 180px;
      min-height: 110px;
      padding: 10px;
      margin-top: 9px;
      margin-bottom: 13px;
      .buttons-group {
        text-align: center;
      }
      .top-button{
        padding-left: 13px;
        padding-right: 13px;
        height: 32px;
      }
      .bottom-button {
        margin-top: 5px;
        height: 32px;
      }
      h5 {
        height: 30px;
        width: 140px;
      }
    }
    .condition-block {
      .info-to-card{
        width: 130px;

        .item{
          max-height: 20px;
        }
        .item-entity{
          overflow: hidden;
          position: relative;
          line-height: 1.2em;
          max-height: 2.4em;
          max-width: 150px;
          text-align: center;
          margin-left: 1em;
          padding-right: 1em;
        }
        .item-entity:before {
          content: '...';
          position: absolute;
          right: 9px;
          bottom: 0;
        }
        .item-entity:after{
          content: '';
          position: absolute;
          right: 9px;
          width: 0.6em;
          height: 1em;
          margin-top: 0.2em;
          background: white;
        }
      }
      .ui button {
        margin: 0 5px 0 5px;
      }
      .button-group {
        margin-top: 20px;
      }
    }
    .action-block {
      width: 100%;
      .info-to-card{
        width: 130px;
        .item{
          max-height: 20px;
        }
      }
    }
  }

.flowBox.isActive {
  width: 180px;
  height: 180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  background-color: darkseagreen !important;
  border: 0;
  opacity: 0.2;
  .choice-buttons {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 1px rgba(0.2, 0.2, 0.2, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 180px;
    min-height: 110px;
    padding: 10px;
    margin-top: 9px;
    margin-bottom: 13px;
    h5 {
      margin-top: -20px;
      height: 30px;
    }
  }
}

.flowBox.isActive:hover {
  border: 3px solid palegreen;
}
h5.item{
  max-width: 120px;
}
.item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.list {
  padding-top: 20px;
  div {
    overflow: hidden;
    width: 130px;
    height: 30px;
    text-overflow: ellipsis;
  }
}

.all-entities, .qbo-action-update-params {
  border-radius: 5px;
  display:none;
  margin-top: -60px;
  min-width: 60px;
  min-height: 20px;
  position: absolute;
  color: white;
  background-color: #1b1c1d;
  z-index: $zIndexOfPopupInMatrixBlock;
  margin-left: 25px;
  padding: 5px;
}
.all-entities::before, .all-entities::after,
.qbo-action-update-params::before, .qbo-action-update-params::after {
  content: '';
  position: absolute;
  left: 20px; bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid #1b1c1d;
}
.all-entities::after, .qbo-action-update-params::after {
  border-top: 10px solid #1b1c1d;
  bottom: -19px;
}
.all-entities.start{
  margin-top: -80px !important;
  margin-left: 10px;
}
.info-to-card:hover .all-entities, .choice-buttons:hover .all-entities,
.info-to-card:hover .qbo-action-update-params.full, .choice-buttons:hover .qbo-action-update-params.full {
  display:block;
}

.qbo-action-update-params.full {
  height: auto;
  width: 150px;
  position: absolute;
  bottom: 70px;
  padding: 5px;
  margin-top: -35px;
  margin-left: -10px;
  word-wrap: break-word;
  white-space: initial;
}

.qbo-action-update-params.empty, .qbo-action-update-params.empty:hover {
  display: none;
}