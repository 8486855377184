@import "../../common-app-variables.scss";

.active-card{
  word-wrap: break-word;
  display: inline-block !important;
  padding: 30px !important;
  color: black !important;
  border: 1px solid #e02222 !important;
  box-shadow: 0 0 5px 1px #e02222 !important;
}
.active-card:hover{
  border: 1px solid #e02222 !important;
  box-shadow: 0 0 5px 1px #e02222 !important;
}
.default-inactive-card{
  word-wrap: break-word;
  display: inline-block !important;
  padding: 30px !important;
  color: black !important;
}
.button-in-card{
  margin-top: 20px;
  width: 100px;
  height: 30px;
  margin-left: 150px;
  color: #919eab;
}

.right-button-error{
  position: fixed;
  right: -30px;
  top: 250px;
  display: block;
  transform: rotate(-90deg);
}

.card-all-content{
  height: auto;
  outline: none;
  overflow: hidden;
}

.card-part-content{
  height: 55px;
  outline: none;
  overflow: hidden;
}

.sidebar-with-errors {
  background-color: #f4f6f8 !important;
  z-index: $zIndexOfRightErrorsSidebar !important;
  top: 55px !important;
}
