@import "../../common-app-variables.scss";

.sidebar.header-segment-sidebar.menu.menu {
  width: auto !important;
  background: #ffffff;
  box-shadow: 0 0 20px rgba(34, 36, 38, 0.15);
  overflow: inherit !important;
  display: flex;
  position: fixed;
  right: 0;
  left: 0;
  z-index: $zIndexOfTopHeaderSidebar !important;
  height: $defaultLeftBarMarginTop !important;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin-right: 20px;
  .right-part {
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown-user:last-child {
      margin-right: 20px;
    };
    .custom-icon {
      color: #007AFF;
      cursor: pointer;
      width: 55px;
      height: 57px;
      padding-top: 16px;
    };
    .custom-icon:last-child {
      margin-right: 20px;
    };
    .custom-icon:hover {
      color: #004bff;
      background: rgba(0, 0, 0, .03) !important;
    };
  }
  .left-part-header {
    display: flex !important;
    align-items: center;
    .header-logo {
      width: 250px;
      a {
        width: 45%;
        margin-left: 15px;
      }
    }
    .checkbox {
      width: 200px;
    }
    label {
      font-family: $defaultFontFamily;
    }
    .quick-start-label {
      display: block !important;
      color: #007AFF;
      cursor: pointer;
      margin-left: 0.2%;
      outline: 0;
      vertical-align: top;
      width: 55px;
      height: 57px;
      white-space: nowrap;
      word-wrap: break-word;
      padding-top: 13px;
    }
    .quick-start-label:hover {
      color: #004bff;
      background: rgba(0, 0, 0, .03) !important;
    }
    .request-a-demo-button {
      padding: 3% 2%;
      width: 38%;
      margin: 0;
    }
  }
  .dropdown-reconnect {
      .item:first-child {
        span {
          color: #999999;
        }
      };
  };
  .dropdown-user {
    padding: 0;
    overflow: visible;
    height: 100%;
    .item {
      .text {
        font-family: $defaultFontFamily;
      }
    }
    .dropdown-user-menu{
      border: none;
    }
    .dropdown-user-menu:after{
      display: none;
    }
    .transition {
      margin-top: 0;
      right: 0;
      left: unset;
      min-width: 165px;
      .item:first-child {
        margin-top: 8px
      };
      .item:last-child {
        margin-bottom: 8px
      }
    }
    .icon{
      margin: auto;
    }
  }
  .dropdown-user:before{
    display: none !important;
  }
  .selected-company {
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    box-shadow: none;
    color: rgba(0,0,0,.87);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 1em;
    margin: 0 12px;
    min-width: 180px;
    min-height: 2.71428571em;
    outline: 0;
    padding: .78571429em 2.1em .78571429em 1em;
    transform: rotateZ(0);
    transition: box-shadow .1s ease,width .1s ease;
    vertical-align: top;
    white-space: nowrap;
    word-wrap: break-word;
    .icon {
      padding: 0;
      margin-right: 10px;
    }
  }
  .selected-company.active {
    border-color: #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    background: rgba(0,0,0,.05);
  }
  .list-companies {
    left: auto;
    right: 0;
    min-width: 180px;
    top: 60% !important;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  }
  .list-companies:after {
    display: none;
  }
}

.header-segment-sidebar {
  border-bottom: 1px solid #C8C7CC !important;
  box-shadow: none !important;
}

.sidebar.header-segment-sidebar.menu.menu:after {
  display: none;
}

@media (not all) and (handheld) {
  width: 100vw;
}