html{
  zoom:1;
}

body {
  background: #f4f6f8 !important;
  overflow: auto !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  margin: 0;
  div.no-match{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: inline-grid;
    text-align: center;
    .ui.icon.header{
      font-size: 60px;
    }
  }
}

.ui.dimmer {
  height: 100vh;
  width: 100vw;
}

.popup {
  width: 600px;
}

.text-cutting {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fade-in {
  animation: fadein 0.8s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.description-text-area textarea {
  resize: none !important;
}
