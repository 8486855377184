@import "../../common-app-variables.scss";

.json-modal {
  z-index: $zIndexOfConfirmModal;
  position: relative !important;
  height: 60vh;
  .header {
    text-align: center;
    .button {
      background: none;
      margin-top: -25px;
      margin-right: -32px;
      color: grey;
      opacity: .8;
    }
  }
  .close.icon {
    color: grey;
    top: 0 !important;
    right: 0 !important;
  }
  .json-edit-modal-content {
    display: flex !important;
    justify-content: space-around;
    text-align: left;
    font-size: 1.5em !important;
    height: 78% !important;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 40 !important;
    border-radius: 4px;
    padding: 20px 20px 60px 20px;
    .left-content {
      width: 50%;
      border-right: 1px solid grey;
      .pre-fields {
        padding: 30px 30px 20px 30px;
        display: flex;
        div {
          width: 38%;
          margin: 0;
        }
      }
      .all-fields {
        margin: 0 20px 12px 0 !important;
        padding: 0 30px;
        .field {
          margin: 0 0 10px 0 !important;
          display: flex;
          .key-input {
            padding: 0 10px 0 0;
            font-size: 0.7em;
            width: 36%;
            margin: auto auto auto 0;
          }
          .value-dropdown {
            font-size: .7em !important;
            width: 50%;
            margin: auto;
            .text {
              width: 100%;
              white-space: normal;
              overflow: hidden;
              word-wrap: break-word;
            }
            .menu {
              margin: 0 0 50px 0;
              overflow: auto !important;
            }
          }
          .delete-field-button {
            color: #919eab;
            background: none;
            padding: 0;
          }
        }
      }
      .submit-button {
        padding: 0 30px;
        .add-button {
          width: 32%;
          margin: 0 10px 10px 0;
        }
      }
    }
    .right-content {
      text-align: center;
      display: block;
      width: 50%;
      .show-json-button {
        margin: 30px 30%;
      }
      .placeholder-json-tree {
        opacity: 0.3;
        text-align: center;
        p:first-child {
          width: 50%;
          margin: 0 auto;
        }
      }
      ul {
        font-size: 0.9em;
        text-align: left;
        margin: 0 3% !important;
      }
    }
  }
  .actions {
    height: 12%;
    display: flex;
    justify-content: center;
    .save-json-button {
      background: #0c9c49;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.json-modal-dimmer {
  background: none !important;
  z-index: $zIndexOfConfirmModal !important;
  opacity: 0.5 !important;
}

.visible.transition.json-edit-modal-content {
  display: flex !important;
}

@media all and (max-height: 850px) {
  .json-modal{
    .header {
      height: 15% !important;
    }
    .json-edit-modal-content {
      height: 70% !important;
    }
    .actions {
      height: 15% !important;
    }
  }
}

@media all and (max-height: 690px) {
  .json-modal{
    .header {
      height: 15% !important;
    }
    .json-edit-modal-content {
      height: 63% !important;
    }
    .actions {
      height: 22% !important;
    }
  }
}