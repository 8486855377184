@import "../../common-app-variables.scss";

.confirm-modal {
  z-index: $zIndexOfConfirmModal;
  .header {
    text-align: center;
  }
  .actions {
    text-align: center !important;
  }
  .content {
    padding: 1.5rem !important;
  }
}