@import "../../common-app-variables.scss";

.help-popup-step-one, .help-popup-step-two,
.help-popup-step-three, .help-popup-step-four,
.help-popup-step-five{
  text-align: center;
  font-size: 1.1em !important;
  border-radius: 5px;
  width: 300px;
  min-height: 20px;
  position: fixed;
  color: black;
  background-color: white;
  margin-left: 260px;
  padding: 10px;
  z-index: $zIndexOfPopupInMatrixBlock;
  line-height: normal;
  border: 1px solid #d4d4d5;
  box-shadow:
          0 2px 4px 0 rgba(34,36,38,.12),
          0 2px 10px 0 rgba(34,36,38,.15);
  i {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.help-popup-step-one, .help-popup-step-two, .help-popup-step-four {
  font-size: 1.1em !important;
  position: fixed;
  margin-left: 260px;
}

.help-popup-step-three, .help-popup-step-five {
  font-size: 1.2em !important;
  position: absolute;
}

.help-popup-step-three{
  margin-left: 100px;
  top: 20%
}

.help-popup-step-five {
  margin-left: 40px;
  top: 30%
}

.help-popup-step-one::before, .help-popup-step-one::after,
.help-popup-step-two::before, .help-popup-step-two::after,
.help-popup-step-three::before, .help-popup-step-three::after,
.help-popup-step-four::before, .help-popup-step-four::after,
.help-popup-step-five::before, .help-popup-step-five::after{
  content: '';
  position: absolute;
  left: -20px;
  bottom: 35%;
  border: 10px solid transparent;
  border-right: 10px solid #d4d4d5;
}

.help-popup-step-one::after, .help-popup-step-two::after,
.help-popup-step-three::after, .help-popup-step-four::after,
.help-popup-step-five::after{
  border-right: 10px solid white;
  bottom: 35%;
  left: -6.5%;
}

@media all and (min-height: 825px) {
  .help-popup-step-one{
    top: 12em;
  }
  .help-popup-step-two{
    top: 15em;
  }
  .help-popup-step-four{
    top: 8.5em;
  }
}

@media all and (min-height: 769px) and (max-height: 824px) {
  .help-popup-step-one{
    top: 12.5em;
  }
  .help-popup-step-two{
    top: 15em;
  }
  .help-popup-step-four{
    top: 8.5em;
  }
}

@media all and (min-height: 640px) and (max-height: 768px) {
  .help-popup-step-one{
    top: 14em;
  }
  .help-popup-step-two{
    top: 16.5em;
  }
  .help-popup-step-four{
    top: 9.5em;
  }
}

@media all and (max-height: 639px) {
  .help-popup-step-one{
    top: 13em;
  }
  .help-popup-step-two{
    top: 15.5em;
  }
  .help-popup-step-four{
    top: 9.5em;
  }
}


.help-popup-step-five::before, .help-popup-step-five::after{
  content: '';
  position: absolute;
  right: 100%;
  border: 10px solid transparent;
  border-right: 10px solid #d4d4d5;
}
.help-popup-step-five::after{
  border-right: 10px solid white;
  right: 99%;
}

.help-popup-step-five.right{
  margin-left: -465px;
}

.help-popup-step-five.right::before, .help-popup-step-five.right::after{
  content: '';
  position: absolute;
  left: 100%;
  border: 10px solid transparent;
  border-left: 10px solid #d4d4d5;
}
.help-popup-step-five.right::after{
  border-left: 10px solid white;
  left: 99.5%;
}

