@import "../../common-app-variables.scss";

.templates-messages-modal-container {
  font-size: 16px !important;
}

.template-messages-modal {
  font-size: 1em !important;
  box-shadow: 1px 2px 15px 1px grey;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
  border-radius: 0 4px 4px 0;

  &.separate {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $zIndexOfTemplatesSeparateModal !important;
    background-color: $leftMenuBackgroundColor !important;
    color: $leftMenuBaseFontColor !important;
  }

  &.email-modal-assigned {
    position: absolute;
    top: 0px;
    left: 100% !important;
    width: 20vw;
    height: 100% !important;
    background-color: #f4f6f8 !important;
  }

  .template-messages-modal-content {
    text-align: center !important;
    padding-bottom: 30px !important;
  }
}

.close-templates-icon {
  z-index: $zIndexOfTemplatesSeparateModal !important;
  position: absolute;
  top: 0px;
  left: calc(100% + 20vw + 5px);
  i {
    color: #767676;
    cursor: pointer;
    font-size: 22px;
  }
}

.templates-modal-header {
  color: #000000;
  text-align: center;
  line-height: 2.3em;
  font-size: 1.2em;
  i {
    font-size: 1.1em;
  }
}

.div-with-card-template {
  margin-top: 1em;
  .card-with-template {
    cursor: pointer;
    color: black;
    width: 18em !important;
    margin: 0 auto;
    background: white !important;
    padding: 1em;
    .content {
      font-size: 1em !important;
      background: white !important;
    }
  }
  .card-with-template:hover {
    box-shadow: 0 0 10px #1b1c1d;
  }
}

.template-messages-modal.separate {
  .card-with-template:hover {
    box-shadow: 0 0 20px #b6b6b7 !important;
  }
}

.template-messages-modal.separate::-webkit-scrollbar-thumb {
  background: #b1b3b5 !important;
  opacity: 0.1;
}