@import "../../common-app-variables.scss";

.ui.sidebar.flow-templates-sidebar {
  height: calc(100% - 17.5em) !important;
  background: #f6f6f6;
  padding: 0 0 2em;
  box-shadow: 10px 2px 10px rgba(34, 36, 38, 0.15) !important;
  font-size: 16px;
  min-width: 20em !important;
  max-width: 30em !important;
  width: auto !important;
  z-index: $zIndexOfTemplatesSideBar !important;
  margin: 10.1em 0 0 250px !important;
}

.ui.sidebar.flow-templates-sidebar.animating {
  z-index: 29 !important;
}

.flow-templates-sidebar-menu {
  background: #f6f6f6;
  margin-top: 1em;
  overflow-y: auto;
  padding: 0 0.5em;
}

.flow-templates-group-menu-item {
  margin-top: 0.1em;
  padding-left: 1em;
  color: $leftMenuBaseFontColor !important;;
  font-family: $defaultFontFamily;
  font-size: 1.1em;
  line-height: 2.5em;
  cursor: pointer;
  &:hover {
    background-color: $leftMenuItemHoverColor !important;
    border-radius: 2px;
  }
  i {
    font-size: 1.2em;
    float: right;
  }
  &.active {
    background-color: #767676 !important;
  }
}

@media all and (min-height: 1025px) {
  .ui.sidebar.flow-templates-sidebar {
    font-size: 18px !important;
    margin-top: 9.5em !important;
    height: calc(100% - 17em) !important;
  }
}

@media all and (min-height: 769px) and (max-height: 1024px) {
  .ui.sidebar.flow-templates-sidebar {
    font-size: 16px !important;
  }
}

@media all and (min-height: 640px) and (max-height: 768px) {
  .ui.sidebar.flow-templates-sidebar {
    font-size: 14px !important;
    height: calc(100% - 19em) !important;
    margin-top: 11em !important;
  }
}

@media all and (max-height: 639px) {
  .ui.sidebar.flow-templates-sidebar {
    font-size: 12px !important;
    height: calc(100% - 22em) !important;
    margin-top: 12em !important;
  }
}