.request-a-demo-modal {
  height: 73vh;
  .request-a-demo-header {
    padding: 3% 0;
    height: 9vh;
    h2 {
      text-align: center;
    }
  }
  .request-a-demo-content {
    height: 55vh;
    overflow: auto;
    .grid {
      margin: 0 !important;
      .row {
        padding: 0.5rem 0;
        .captcha-error {
          color: red;
        }
        .re-captcha-with-error-div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;
        }
      }
    }
  }
  .actions {
    padding: 2% 0;
    height: 9vh;
    text-align: center;
    .request-a-demo-send-button, .request-a-demo-cancel-button {
      width: 15%;
    }
  }
}