@import "../../common-app-variables.scss";

.quick-start-modal {
  z-index: $zIndexOfConfirmModal !important;
  box-shadow: 0 0 10px #ffffff !important;
  display: block;
  .header {
    text-align: center;
    .text {
      margin-left: 30%;
    }
    .button {
      margin-top: -30px;
      margin-right: -5%;
      background: none;
    }
    .icon {
      font-size: 2.5em;
    }
  }
  .content {
    text-align: center;
    font-size: 1em !important;
    .icon {
      transition: transform .25s;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    .checkbox {
      margin: 0 0 10px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      label {
        width: 35%;
        color: white !important;
      }
    }
    .buttons {
      display: block;
      text-align: center;
      .button {
        margin-right: 10px;
      }
    }
  }
}
