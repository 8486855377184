@import "../../common-app-variables.scss";

.left-menu-sidebar-container {
  font-size: 16px;
}

.sidebar.left-menu-sidebar {
  font-size: 16px;
  width: 250px !important;
  z-index: $zIndexOfLeftMenuSidebar !important;
  border: none;
  background-color: $leftMenuBackgroundColor !important;
  .left-menu-sidebar-wrapper {
    position: absolute;
    background: $defaultSideBarColor;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
}

.left-menu-logo-header {
  height: $defaultLeftBarMarginTop;
  display: flex;
  background: $defaultSideBarColor;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  .header-text {
    font-family: $defaultFontFamily;
    font-size: 1.3em;
  };
  img {
    width: 57px !important;
    height: 45px !important;
  }
}

.left-menu-inner-header {
  text-align: center;
  font-family: $defaultFontFamily;
  background: $defaultSideBarColor;
  font-size: 1.2em;
  font-weight: 700;
  padding: 0 0.5em;
  margin: 0.5em auto;
  color: $leftMenuBaseFontColor !important;
  .menu-header-subtitle {
    font-size: 0.85em;
    font-weight: 400;
  }
}

.left-menu-main-content-header {
  text-align: center;
  font-family: $defaultFontFamily;
  font-size: 1.0em;
  font-weight: 700;
  padding: 0.5em;
  color: $leftMenuBaseFontColor !important;
  .menu-header-subtitle {
    font-size: 0.85em;
    font-weight: 400;
  }
}

.left-menu-flow-name-block {
  margin: 1.2em auto 0.8em;
  background: $defaultSideBarColor;
  padding: 0 0.5em;
  font-size: 1em;
  .input {
    color: #2185d0;
    font-size: 1em;
  }
  .divider {
    margin: 1em auto 0em;
  }
}

.left-menu-main-content {
  width: 100%;
  position: absolute;
  top: 10em;
  bottom: 0;
  .main-menu-content-wrapper {
    height: calc(100% - 9em);
    background: $defaultSideBarColor;
    position: absolute;
    width: 100%;
    padding: 0.1em;
    overflow-y: auto;
    .active-content {
      box-shadow: 0 0 0 #5cb85c;
      animation: pulsate 1s infinite;
    }
  }

  .main-menu-content-wrapper::-webkit-scrollbar-thumb {
    background: #b1b3b5 !important;
    opacity: 0.1;
  }
}

.select-trigger-block {
  padding: 0.5em;
  .dropdowns-group {
    & > :nth-child(1) {
      .menu {
        min-height: 200%;
        max-height: calc(100vh - 30em) !important;
      }
    }
    & > :nth-child(2) {
      margin-top: 1em;
      .menu {
        min-height: 150%;
        max-height: calc(100vh - 35em) !important;
      }
    }
  }
}

.select-action-block {
  padding: 0.5em;
  padding-bottom: 2.5em !important;
  .dropdowns-group {
    .input, .form, .button, .dropdown, .checkbox {
      margin-top: 1em;
    }
    .dropdown {
      .menu {
        min-height: 150%;
      }
      &:nth-child(1) {
        .menu {
          max-height: calc(100vh - 30em) !important;
        }
      }
      &:nth-child(2) {
        .menu {
          max-height: calc(100vh - 35em) !important;
        }
      }
      &:nth-child(3) {
        .menu {
          max-height: calc(100vh - 40em) !important;
        }
      }

    }

    .button {
      width: 100%;
    }
    .checkbox label {
      color: $leftMenuBaseFontColor !important;
    }

    .form {
      font-size: 1em !important;
    }

  }
  .left-menu-item.template-messages {
    margin: 1em -0.5em 0 -0.5em;
    padding-left: 1em;
  }
}

.select-condition-block {
  padding: 0.5em;
  .dropdowns-group {
    padding-bottom: 30px;
    .input, .dropdown {
      margin-top: 0.5em;
    }

    .condition-inner-header {
      text-align: center;
      font-size: 1.0em;
      font-weight: 700;
      padding: 0.5em 0.5em 0;
      color: $leftMenuBaseFontColor !important;
      .menu-header-subtitle {
        font-size: 0.9em;
        font-weight: 400;
      }
    }

    .dropdown {
      .menu {
        min-height: 150%;
        max-height: 200% !important;
      }
    }
    .dropdown:nth-child(1) {
      .menu {
        max-height: calc(100vh - 30em) !important;
      }
    }
    .dropdown:nth-child(2),
    .dropdown:nth-child(3) {
      .menu {
        max-height: calc(100vh - 35em) !important;
      }
    }
    .dropdown:nth-child(4) {
      .menu {
        max-height: calc(100vh - 40em) !important;
      }
    }
    .dropdown:nth-child(5) {
      .menu {
        max-height: calc(100vh - 45em) !important;
      }
    }
  }
}

.left-menu-bottom-group {
  position: absolute;
  background: $defaultSideBarColor;
  bottom: 0;
  width: 100%;
  height: 11em;
  .left-menu-item.templates {
    font-family: $defaultFontFamily;
    padding: 0 0.5em 0 1.0em;
  }

  .buttons-group {
    padding: 0 0.5em;
    button {
      font-family:$defaultFontFamily;
      width: 100%;
      &:first-of-type {
        background: #428bca;
      };
      &:not(:first-of-type) {
        margin-top: 0.5em;
        background: #959595;
      };
    }
  }
}

.left-menu-bottom-group-fetch {
  height: 13em;
}

.left-menu-item {
  color: $leftMenuBaseFontColor !important;
  font-size: 1.1em;
  line-height: 2.5em;
  cursor: pointer;
  &:hover {
    background-color: $leftMenuItemHoverColor !important;
    border-radius: 2px;
  }
  i {
    font-size: 1.2em;
    float: right;
  }
}

.left-menu-small-link {
  padding: 1em 1em;
  font-family: $defaultFontFamily;
  text-align: right;
  .clickable {
    color: $leftMenuBaseFontColor !important;
    cursor: pointer;
  }
}

@keyframes pulsate {
  10% {
    box-shadow: 0 0 2px #5cb85c,
    inset 0 0 2px #5cb85c;
  }
  20% {
    box-shadow: 0 0 5px #5cb85c,
    inset 0 0 5px #5cb85c;
  }
  50% {
    box-shadow: 0 0 10px #5cb85c,
    inset 0 0 10px #5cb85c;
  }
  70% {
    box-shadow: 0 0 5px #5cb85c,
    inset 0 0 5px #5cb85c;
  }
  100% {
    box-shadow: 0 0 0 #5cb85c,
    inset 0 0 0 #5cb85c;
  }
}

@media all and (min-height: 1025px) {
  .left-menu-sidebar-container,
  .sidebar.left-menu-sidebar {
    font-size: 18px !important;
  }
}

@media all and (min-height: 769px) and (max-height: 1024px) {
  .left-menu-sidebar-container,
  .sidebar.left-menu-sidebar {
    font-size: 16px !important;
  }
}

@media all and (min-height: 640px) and (max-height: 768px) {
  .left-menu-sidebar-container,
  .sidebar.left-menu-sidebar {
    font-size: 14px !important;
  }
  .left-menu-main-content {
    top: 11em;
  }
  .left-menu-bottom-group {
    height: 12em;
  }
  .select-action-block {
    padding-bottom: 3.5em !important;
  }

}

@media all and (max-height: 639px) {
  .left-menu-sidebar-container,
  .sidebar.left-menu-sidebar {
    font-size: 12px !important;
  }
  .left-menu-main-content {
    top: 11em;
  }

  .left-menu-bottom-group {
    height: 13em;
  }
  .select-action-block {
    padding-bottom: 4.5em !important;
  }
}
