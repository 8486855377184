.synder-entity-modal {
  .header {
    background: #f9fafb !important;
    text-align: center;
  }
  .close.icon {
    color: grey;
    top: 0 !important;
    right: 0 !important;
  }
  .row-delete-icon {
    color: #919eab;
    cursor: pointer;
    margin-top: 30px;
  }

  .synder-action-entity-form {
    .content {
      max-height: calc(70vh);
      min-height: 310px;
      overflow: auto;
      display: block;
      width: 100%;
      font-size: 1em;
      line-height: 1.4;
      padding: 1.5rem;
      background: #f9fafb;

      .tiny-divider{
        margin: 0 !important;
      }
    }
    .actions {
      padding: 1rem 1rem;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      text-align: center;
      background: #f9fafb;
      .button {
        width: 90px !important;
      }
    }
    .updateAll {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      .label {
        margin-left: 10px;
      }
    }
  }
}