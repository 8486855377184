@import "../../common-app-variables.scss";

.confirm-rewrite-modal {
  z-index: $zIndexOfConfirmModal;
  border: 1px solid #d4d4d5;
  .header {
    text-align: center;
  }
  .actions {
    text-align: center !important;
  }
  .content {
    padding: 1.5rem !important;
  }
}