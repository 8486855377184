@import "../../common-app-variables.scss";

.email-modal {
  z-index: $zIndexOfConfirmModal;
  height: 75vh;
  width: 55vw !important;
  position: relative !important;
  &.big {
    left: -5vw;
  }

  .editorClassName {
    min-height: 300px;
    width: 100%;
    margin: auto;
  }
  .template {
    font-size: 1.4em;
    text-align: right;
    padding-right: 12px;
    padding-bottom: 5px;
    color: gray;
    cursor: pointer;
    line-height: 1.4em;
    &.closed:after {
      content: ">>" !important;
      padding-left: 1em;
    }
    &.opened:after {
      content: "<<" !important;
      padding-left: 1em;
    }
  }

  .header {
    display: flex !important;
    justify-content: space-between;
    font-size: 2em !important;
    height: 13%;
    .text {
      margin-left: 35%;
    }
    .button {
      background: none;
      margin-top: -25px;
      margin-right: -17px;
    }
  }
  .content {
    text-align: center;
    font-size: 1.5em !important;
    height: 71%;
    .editorClassName {
      min-height: 300px;
    }
  }
  .checkbox {
    margin-top: 10px;
  }
  .ok-button {
    margin-left: 84%;
    margin-bottom: 3%;
    width: 100px;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}

.email-modal-dropdown {
  background: none !important;
  z-index: $zIndexOfConfirmModal !important;
  opacity: 0.5 !important;
}

@media all and (min-width: 1366px) and (max-width: 1535px) {
  .email-modal {
    &.big {
      left: -15vw;
    }
  }
  .template-messages-modal {
    &.email-modal-assigned {
      width: 30vw !important;
    }
  }
  .close-templates-icon {
    left: calc(100% + 30vw + 5px) !important;
  }
}

@media all and (min-width: 1280px) and (max-width: 1365px) {
  .email-modal {
    height: 80vh;
    width: 70vw !important;
    &.big {
      width: 60vw !important;
      left: -15vw;
    }
  }
  .template-messages-modal {
    &.email-modal-assigned {
      width: 30vw !important;
    }
  }
  .close-templates-icon {
    left: calc(100% + 30vw + 5px) !important;
  }
}

@media all and (min-width: 1024px) and (max-width: 1279px) {
  .email-modal {
    height: 85vh;
    width: 85vw !important;
    .content {
      height: 69% !important;
    }
    &.big {
      width: 60vw !important;
      left: -18vw;
    }
  }
  .template-messages-modal {
    &.email-modal-assigned {
      width: 33vw !important;
    }
  }
  .close-templates-icon {
    left: calc(100% + 33vw + 5px) !important;
  }
}

@media all and (max-width: 1023px) {
  .email-modal {
    height: 90vh;
    width: 90vw !important;
    .content {
      height: 66%;
    }
    &.big {
      width: 60vw !important;
      left: -18vw;
    }
  }
  .template-messages-modal {
    &.email-modal-assigned {
      width: 33vw !important;
    }
  }

  .close-templates-icon {
    left: calc(100% + 33vw + 5px) !important;
  }
}
