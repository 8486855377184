@import "../../common-app-variables.scss";

.ui.three.column.padded.grid{
  width: 240px;
  height: 240px;
  .first {
    width: 240px;
    height: 30px;
  }

  .second {
    width: 240px;
    height: 180px;
  }
  .simple{
    width: 30px;
    height: 30px;
    padding-left: 0 !important;
  }
  .double-simple{
    width: 180px;
    height: 180px;
  }
  .vertical-top-content{
    z-index: $zIndexOfBlockConnector;
    width: 180px;
    height: 30px;
    margin-left: 0;
  }
  .vertical-top-content.block {
    z-index: $zIndexOfConditionBlock !important;
  }
  .vertical-bottom-content{
    z-index: $zIndexOfConditionChoiceBottom;
    width: 180px;
    height: 30px;
    margin-left: 0;
  }
  .horizontal-content {
    padding-top: 20%;
    padding-left: 0;
    width: 30px;
    height: 180px;
  }
  .horizontal-condition-content{
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    width: 30px;
    height: 180px;
  }
}


/* styles for Connectors */

.vertical-connector {
  width: 51.5% !important;
  height: 50px !important;
  border-right: 1px solid #A9A9A9;
}

.horizontal-connector {
  border: 0 solid aqua;
  z-index: $zIndexOfConnectors;
  width: 30px !important;
  height: 50px !important;
  border-bottom: 1px solid #A9A9A9;
}

.parent-horizontal-connector {
  z-index: $zIndexOfConnectors;
  width: 30px;
  height: 50px;
  border-bottom: 1px solid #A9A9A9;
}

.parent-vertical-connector {
  margin-left: 0 !important;
  width: 51% !important;
  height: 30px !important;
  border-right: 1px solid #A9A9A9;
}

div.top-bottom-line {
  width: 51% ;
  height: 50px;
  border-right: 1px solid #A9A9A9;
  padding-top: 10px;
  .top-bottom-block {
    padding-top: 0;
    margin-top: -21px;
    border-radius: 5px;
    z-index: $zIndexOfConditionChoiceTop;
    background: #ffffff;
    border: 1px solid #A9A9A9;
    width: 35px;
    height: 25px;
    font-size: 0.8em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }
  .bottom-top-block {
    z-index: $zIndexOfConditionChoiceTop;
    padding-top: 0;
    margin-top: 10px;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #A9A9A9;
    width: 35px;
    height: 25px;
    font-size: 0.8em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }
}

div.top-bottom-block {
  padding-top: 10px;
  margin-left: 78%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

div.bottom-top-block {
  padding-top: 10px;
  margin-left: 78%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

div.left-right-line {
  width: 29px;
  height: 50px;
  text-align: right;
  z-index: $zIndexOfConditionConnector;
  border-bottom: 1px solid #A9A9A9;
  cursor: pointer;
  .left-right-block {
    margin-top: 36px;
    margin-left: 13px;
    border-radius: 5px;
    width: 35px;
    height: 25px;
    font-size: 0.8em;
    border: 1px solid #A9A9A9;
    background: #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

div.left-line {
  margin-left: -10px;
  width: 40px;
  height: 50px;
  text-align: right;
  border-bottom: 1px solid #A9A9A9;
  cursor: pointer;
  .left-right-block{
    margin-top: 36px;
    margin-right: 8px;
    margin-left: -7px;
    background: #ffffff;
    border: 1px solid #A9A9A9;
    border-radius: 5px;
    font-size: 0.8em;
    width: 35px;
    height: 25px;
  }
}

div.left-right-block {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.left-right-row {
  display: flex;
  width: 80px;
  height: 180px;
}

.flow-area-content{
  text-align: center;
  zoom: normal;
  display: inline-block;
  top: 0;
}
