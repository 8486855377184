@import "../../common-app-variables.scss";

.general-accordion-fields-component {
  margin: 0.7em auto;
}

.combine-accordion-field {
  padding: 0.6em 0.4em 0.1em 2.4em;
  cursor: pointer;
  font-size: 1.0em !important;
  text-align: left;
  & > :nth-child(1) {
    text-align: left;
    span{
      padding-left: 1.2em;
    }
  }
  &.active {
    & > :nth-child(1) {
      &:before {
        content: "-" !important;
        padding-left: 0.17em;
      }
      span{
        padding-left: 1.3em;
      }
    }
  }
  &.false {
    & > :nth-child(1) {
      &:before {
        content: "+" !important;
      }
    }
  }
}

.simple-accordion-field {
  text-align: left;
  padding: 0.6em 0.4em 0.1em 2.5em;
  font-size: 1.0em;
  li {
    span {
      padding: 5px;
    }
    span:hover {
      cursor: pointer;
      background: white;
      box-shadow: 3px 4px 23px -1px grey;
      border-radius: 5px;
      border: none;
    }
  }
}

.general-accordion-fields-component {
  &.separate {
    .simple-accordion-field {
      li {
        span:hover {
          background: $leftMenuItemHoverColor;
          box-shadow: none;
        }
      }
    }
  }
}
