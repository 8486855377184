// z-indexes
$zIndexOfConfirmModal: 55;
$zIndexOfTemplatesSideBar: 31;
$zIndexOfTopHeaderSidebar: 31;
$zIndexOfLeftMenuSidebar: 30;
$zIndexOfTemplatesSeparateModal: 28;
$zIndexOfPopupInMatrixBlock: 27;
$zIndexOfLeftMenuDimmer: 26;
$zIndexOfRightErrorsSidebar: 25;
$zIndexOfBigSquareInMatrix: 20;
$zIndexOfConditionChoiceTop: 20;
$zIndexOfZoomButtons: 20;
$zIndexOfConditionChoice: 14;
$zIndexOfBlockConnector: 14;
$zIndexOfConditionChoiceBottom: 14;
$zIndexOfConditionBlock: 13;
$zIndexOfConditionConnector: 10;
$zIndexOfConnectors: 1;

// left-menu
$leftMenuBackgroundColor: #f6f6f6;
$leftMenuBaseFontColor: #000000;
$leftMenuItemHoverColor: rgba(129, 129, 129, 0.15);
$defaultLeftBarMarginTop: 57px;
$defaultSideBarColor: #f6f6f6;
$defaultFontFamily: 'Open sans', sans-serif;