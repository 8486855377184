.react-tel-input {
  margin-top: 15px;
  background-color: #fff;
  min-height: 2.71428571em;
  border-radius:.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);

  &.error {
    background-color: #fff6f6;
  }

  input {
    border: none !important;
    background-color: inherit;
    min-height: 2.71428571em;
    width: auto !important;
  }
}
