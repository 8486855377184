@import "templates-messages-modal.scss";
@import "confirm-modal.scss";
@import "quick-start-modal.scss";
@import "emeail-modal.scss";
@import "json-edit-modal.scss";
@import "confirm-rewrite-modal.scss";
@import "provider-entity-modal";
@import "synder-entity-modal";
@import "request-a-demo-modal";

.modals {
  background: none !important;
}