@import "../../common-app-variables.scss";

.ui.sidebar.flow-templates-messages-sidebar {
  position: fixed;
  height: calc(100% - 17.5em) !important;
  padding: 0 0em 2em;
  font-size: 16px;
  width: 22em !important;
  z-index: $zIndexOfTemplatesSeparateModal !important;
  margin: 10em 0 0 250px !important;
}

@media all and (min-height: 1025px) {
  .ui.sidebar.flow-templates-messages-sidebar {
    font-size: 18px !important;
    margin-top: 9.5em !important;
    height: calc(100% - 17em) !important;
  }
}

@media all and (min-height: 769px) and (max-height: 1024px) {
  .ui.sidebar.flow-templates-messages-sidebar {
    font-size: 16px !important;
  }
}

@media all and (min-height: 640px) and (max-height: 768px) {
  .ui.sidebar.flow-templates-messages-sidebar {
    font-size: 14px !important;
    height: calc(100% - 19em) !important;
    margin-top: 11em !important;
  }
}

@media all and (max-height: 639px) {
  .ui.sidebar.flow-templates-messages-sidebar {
    font-size: 12px !important;
    height: calc(100% - 22em) !important;
    margin-top: 12em !important;
  }
}